.button {
  border: 2px solid black;
  align-self: center;
  align-items: center;
  width: fit-content;
  border-radius: 10vh;
  background: #ebebdb;
  font-weight: bold;
  position: relative;
  height: 4.5vh;
  width: 4.5vh;
  text-align: center;
  line-height: 4.3vh;
  margin: 0 .5vw;
  cursor: pointer;
  /* letter-spacing: 1.2vh;
  text-indent: 1.2vh; */
  font-size: 2.57143vh;
}

.button-child,
.button-child-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid black;
  border-radius: 10vh;
  background: #f9dc5c;
  left: -.6vh;
  top: -.6vh;
  /* transition: 0.06s; */
  user-select: none;
}

.button-child a {
  text-decoration: none;
  color: #000;
  display: block;
}

.button .button-child:active,
.button .button-child-icon:active {
  left: -2px !important;
  top: -2px !important;
  /* border: 0 !important; */
}

.button-hover:hover .button-child,
.button-hover:hover .button-child-icon {
  transition: left 0.2s,top 0.2s;
  color: #fff;
  background-color: #fa4716 !important;
}

.button-hover:hover .button-child a {
  transition: 0.2s;
  color: #fff;
}

.button-active .button-child a {
  color: #fff;
}

.button-active .button-child {
  color: #fff;
  background-color: #fa4716 !important;
}

@media screen and (max-width:812px) {
  .button-child-icon {
    font-size: 16px;
  }
}