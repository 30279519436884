.image {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.image img {
  /* border-radius: 5px; */
}

.image_title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 18px;
  line-height: 25px;
  margin-top: 5px;
}

.image_time {
  font-size: 16px;
  line-height: 25px;
  color: #999;
  display: flex;
  height: 25px;
  overflow: hidden;
}

.image_time span:nth-child(1) {
  /* flex: 1; */
  flex: 1;
  min-width: 100px;
}

.image_modal {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /* transform: translateX(450%); */
  z-index: 3001;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_back {
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
}

.modal_swiper_box {
  text-align: center;
  color: #fff;
  line-height: 30px;
  position: relative;
  width: 90%;
  /* padding: 100px; */
  max-width: 1200px;
  /* overflow-y: auto; */
}

.modal_swiper {
  height: 100%;
  width: 100%;
  /* min-height: 50vw; */
  opacity: visible;
  position: static;
  overflow: hidden;
  background-color: #000;
}

.swiper-wrapper {}

.image_box {
  /* border: 4px solid #fff; */
  max-height: 100vh;
  overflow-y: scroll;
  border-radius: 10px;
  /* overflow: hidden; */
  /* height: calc(100% - 8px) !important; */
}

.image_box img,
.image_box video {
  width: 100%;
  height: 100%;
  display: block;
  /* width: calc(100% + 6px);
  height: calc(100% + 6px);
  transform: translate(-3px, -3px); */
}

.swiper_control {
  width: 5vw;
  overflow: hidden;
}

.swiper_control_left {
  position: absolute;
  left: -5vw;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none !important;
  cursor: pointer;
}

.swiper_control_right {
  position: absolute;
  right: -5vw;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
}

.modal_swiper_box span {
  line-height: 30px;
}

@media screen and (max-width:812px) {
  .swiper_control {
    display: none;
  }

  .image_title {
    margin-top: 4px;
    font-size: 14px;
    line-height: 15px;
  }

  .image_time {
    font-size: 12px;
    color: #999;
    line-height: 15px;
    height: 15px;
    display: flex;
    flex-direction: row;
  }

  .modal_swiper .swiper-slide {
    height: 100% !important;
    position: relative;
  }

  .image_box img,
  .image_box video {
    width: 100%;
    height: 100%;
    transform: translate(0, 0);
  }

  .modal_swiper_box {
    /* height: 48vw; */
  }
}

.modal_swiper_box span {
  position: absolute;
  display: block;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1200px;
  z-index: 1;
}