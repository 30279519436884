.works {
  /* position: fixed; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2001;
  background-color: #fff;
}

.works .first_head {
  background-color: #fa4716;
  height: 9.7vh;
}

.works_list_box {
  padding: 2vw 10px;
  overflow-y: scroll;
  height: calc(96vh - 9.7vh);
}

.works_list_box .list_box {
  display: flex;
  flex-wrap: wrap;
}

.list_item {
  width: 25%;
  position: static;
}

@media screen and (min-width:1920px) {
  .list_item {
    width: 25%;
  }
}

@media screen and (min-width:1200px) and (max-width:1919px) {
  .list_item {
    width: 25%;
  }
}

@media screen and (min-width:812px) and (max-width:1200px) {
  .list_item {
    width: 33.3333%;
  }
}

@media screen and (max-width:812px) {
  .list_item {
    width: 50%;
  }

  .works .first_head {
    height: 68px;
  }

  .works_list_box {
    padding: 2vw .6vw;
    height: calc(98vh - 68px);
  }

  .list_item .image img {}

  .list_item .image {
    margin: .6vw !important;
    margin-bottom: 20px !important;
  }
}