.search {
  border-radius: 3vw;
  border: 2px solid black;
  background: #f9dc5c;
  width: fit-content;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 4vh;
}

.search input {
  border: none;
  padding-left: 2vw;
  color: #333;
  background: none;
  line-height: 2vw;
  font-size: 1.2vw;
  width: 8vw;
}

.search input:focus-visible {
  border: none !important;
  outline: none;
}

.search .icon {
  cursor: pointer;
  height: 2.4vw;
  width: 2.4vw;
  margin-right: 1vw;
}

.search .icon img {
  width: 80%;
  height: 80%;
  margin: 10%;
  top: 10%;
  display: block;
}

@media screen and (max-width:812px) {
  .search {
    height: 30px;
    border-radius: 30px;
    border-width: 1.5px;
  }

  .search .icon {
    height: 24px;
    width: 24px;
  }
  .search input {
    width: 12vw;
  }
}