.App {
  text-align: center;
}

@font-face {
  font-family: 'hp';
  /* font-display: swap; */
  src: url('/public/hp.woff');
}

@font-face {
  font-family: 'dincond';
  src: url('/public/DINCOND-BOLD.OTF');
}

.skew_text {
  font-family: 'hp';
  text-align: justify;
}

.dincond {
  font-family: 'dincond';
}

div,
button,
img,
html input[type="button"] {
  outline: none;
  user-select: none !important;
  -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
}

::-webkit-scrollbar {
  width: 0px;
  background: gray;
}

img {
  user-select: none;
  display: block;
}

.contact_box {
  position: fixed;
  bottom: 2vw;
  left: 0;
  margin-left: 2vw;
  z-index: 2000;
}

.contact_box img {
  pointer-events: all;
}

.contact_item {
  transition: 0.4s;
  border: 2px solid black;
  border-radius: 3em;
  background: #fff;
  font-size: 1vw;
  position: relative;
  text-align: center;
  width: fit-content;
  max-width: 16em;
}

.contact_item .contact_item_text {
  background-color: #fff;
  position: relative;
  z-index: 1;
  line-height: 1.4em;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 3em;
  min-height: 2.8em;
  padding: .2em 1.4em;
}

.contact_item:nth-child(1):after {
  left: 1.6vw;
  bottom: -.4vw;
  transform: rotate(40deg) skew(40deg);
  border-left: none;
  border-bottom: 2px solid black;
  border-right: 2px solid black;
}

.contact_item::after {
  content: ' ';
  height: 2vw;
  width: 2vw;
  position: absolute;
  background-color: #fff;
  transform: rotate(-40deg) skew(-40deg);
  right: 1.6vw;
  bottom: -.4vw;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
}

.cartoon_box {
  position: fixed;
  bottom: 2vw;
  right: 2vw;
  z-index: 2000;
  height: 8vw;
  width: 8vw;
  z-index: 2001;
}

.cartoon_person {}

@media screen and (max-width:812px) {
  .contact_box {
    left: 0;
    bottom: 0;
    margin-left: 0;
  }

  .contact_box img {
    position: absolute !important;
    bottom: 2vw;
    left: 2vw;
  }

  .contact_list {
    position: absolute;
    bottom: 24.5vw;
    width: 100vw;
    /* height: 100vw; */
  }

  .contact_item {
    height: auto;
    max-width: 50vw;
    border-radius: 30em;
    border: 1px solid black;
    position: absolute;
  }

  .contact_item:nth-child(3) {
    margin-left: 16vw !important;
  }

  .contact_item::after {
    bottom: -.5vw;
    right: 6em;
  }

  .contact_item:nth-child(1):after {
    left: 6em;
    border-bottom: 1px solid black !important;
    border-right: 1px solid black !important;
  }

  .contact_item::after {
    border-left: 1px solid black;
    border-bottom: 1px solid black;
  }

  .contact_item .contact_item_text {
    padding: .5vw 4vw;
  }

  .cartoon_box {
    height: 14vw;
    width: 14vw;
  }
}

.face_modal_scale:active {
  transform: scale(0.8);
}