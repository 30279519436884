.header {
  display: flex;
  padding: 0 4vh;
  height: 9.7vh;
  align-items: center;
  position: fixed;
  z-index: 2002;
  width: calc(100% - 8vh);
}

.header_logo {
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 2vh;
}

.header_logo img {
  height: 52%;
}

.header_flex_box {
  display: flex;
}

.header_phone_menu {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #f9dc5c;
  left: 0;
  top: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
}

.header_phone_buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.header_phone_buttons .button {
  margin: 4% 0;
}

.header_phone_buttons a {
  font-size: 3.4vh;
}

.header_phone_icons {
  display: flex;
  margin-top: 10;
  justify-content: center;
  margin-bottom: 20%;
}

.header_phone_icons .button-child-icon {
  font-weight: bold;
  font-size: 2.8vh;
}

@media screen and (max-width:812px) {
  .header {
    height: 68px;
    padding: 0 5vw;
    width: calc(100% - 10vw);
  }
}