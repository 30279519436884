.home {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fa4716;
  /* transition: .2s; */
}

/* 第四屏 */
.fourth_page {}

.fourth_page .fourth_head {
  background-color: #fa4716;
  height: 9.7vh;
  width: 150%;
  z-index: 1;
}

.fourth_page_box {
  background-color: #f9dc5c;
  height: calc(100vh - 9.7vh);
  position: relative;
  width: 150%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.fourth_item {
  width: 33.333%;
}

.fourth_one span {
  animation: 1s;
  display: inline-block;
}

.fourth_two_item {
  display: flex;
  position: absolute;
  width: 24%;
  height: 5vw;
}

@media screen and (max-width: 812px) {
  .fourth_two_item {
    height: 12vw;
  }

  .fourth_page .fourth_head {
    height: 68px;
  }

  .fourth_page_box {
    height: 100%;
  }
}

.fourth_two_arrow {
  position: absolute;
  align-items: center;
  align-self: center;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.fourth_two_item .item_num {
  flex: 1;
}

.fourth_two_item .item_num img {
  height: 100%;
}

.fourth_two_item .item_right {
  width: 50%;
  text-align: center;
  font-size: .9vw;
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.fourth_two_item .item_right div {
  flex: 1;
  height: 50%;
  position: relative;
}

.fourth_two_item .item_right img {
  width: 70%;
  margin: auto;
}

/* skew */
.skew_text {
  transform-origin: bottom;
  animation: 1s;
  transition: 'skew .2s';
  /* transform: skew(10deg); */
}


@media (max-width:812px) {
  .home {
    display: flex;
    position: absolute;
    flex-direction: column;
    height: auto;
    /* transform: none !important; */
  }

  .fourth_page_box {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .fourth_item {
    width: 90%;
    margin: auto;
    height: 50vh;
  }
  .fourth_item:nth-child(2),.fourth_item:nth-child(3){
    display: flex;
    align-items: center;
  }
}