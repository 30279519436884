/* 第二屏 */
.second_page {
  height: 100%;
  /* background-color: #fb4716; */
  position: relative;
  width: 200%
}

.second_container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.second_list_ack {
  position: absolute;
  width: 80vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.second_list_box {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.second_list_item {
  height: 32vw;
  width: 56vw;
  margin: auto;
  background-color: black;
  transform-origin: 250vw 0;
  position: absolute;
  left: 50%;
  top: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

.second_list_item img,
.second_list_item a {
  display: block;
  height: 100%;
  width: 100%;
}

.swiper_button {
  position: absolute;
  top: 50%;
  height: 28px;
  width: 28px;
  border: none;
  background: none;
  z-index: 203;
}

.swiper_button img {
  height: 100%;
  width: 100%;
}

.swiper_button_prev {
  left: -33px;
}

.swiper_button_next {
  right: -33px;
}

.swiper_button_prev img {
  transform: rotate(180deg);
}

@media screen and (max-width:812px) {
  .second_page {
    width: 100% !important;
  }

  .second_container {
    background-color: #f9dc5c;
    width: 170vw;
    height: 170vw;
  }

  .second_container .second_list_ack {
    width: 160vw;
    display: block;
    transform: translate(-50%, -50%) rotate(90deg);
    transform-origin: center;
  }

  .second_list_item {
    height: 120vw;
    width: 80vw;
    background: none;
  }

  .second_list_item .swiper {
    width: 80vw;
    height: 120vw;
    position: static;
    overflow: visible !important;
  }

  .second_list_item .swiper {}
}