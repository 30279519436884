*,
*::after,
*::before {
	/* box-sizing: border-box; */
}

:root {
	font-size: 16px;
}

body {
	--color-wel: #fff;
	margin: 0;
	--color-text: #111;
	--color-bg: #f0f0f0;
	/* 
	--color-link-hover: #000;  */
	/* color: var(--color-text);
	background-color: var(--color-bg);
	/* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: highgate, sans-serif; */
	--cursor-stroke: #fff;
	--cursor-stroke-width: 1.5px;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 1);
	}
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	font: inherit;
}

.unbutton:focus {
	outline: none;
}

.frame {
	padding: 3rem 5vw;
	text-align: center;
	position: relative;
	z-index: 1000;
}

.frame__title {
	font-size: 1rem;
	margin: 0 0 1rem;
	font-weight: normal;
}

.frame__links {
	display: inline;
}

.frame__links a:not(:last-child),
.frame__demos a:not(:last-child) {
	margin-right: 1rem;
}

.frame__demos {
	margin: 1rem 0;
}

.frame__demo--current,
.frame__demo--current:hover {
	color: var(--color-text);
}

.wel {
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: flex-start;
	align-items: center;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: highgate, sans-serif;
}

.wel {
	text-align: center;
}

.wel__item {
	display: inline-block;
	/* margin-bottom: 9vh; */
	transform: translateY(-34%);
	text-decoration: none;
	color: var(--color-wel);
	font-family: vortice-concept, sans-serif;
	/* font-weight: bold; */
	/* border: 1px solid black; */
}

.wel__item span {
	display: block;
}

.wel__item-title {
	font-size: 12vw;
}

.wel__item .splitting .word {
	height: 12vw !important;
	line-height: 12vw;
	display: block;
	/* border: 1px solid black; */
	overflow: hidden;
	padding: 0 2vw;
}

.wel__item-sub {
	/* font-size: 1.5vw; */
	display: block;
}

@media screen and (max-width:812px) {
	.wel__item-title span {
		font-size: 14vw;
	}
}

@media screen and (min-width: 53em) {
	.frame {
		position: fixed;
		text-align: left;
		z-index: 100;
		top: 0;
		left: 0;
		display: grid;
		align-content: space-between;
		width: 100%;
		max-width: none;
		height: 100vh;
		padding: 1.5rem 2rem;
		pointer-events: none;
		grid-template-columns: 75% 25%;
		grid-template-rows: auto auto auto;
		grid-template-areas: 'title links'
			'... ...'
			'... demos';
	}

	.frame__title-wrap {
		grid-area: title;
		display: flex;
	}

	.frame__title {
		margin: 0;
	}

	.frame__tagline {
		position: relative;
		margin: 0 0 0 1rem;
		padding: 0 0 0 1rem;
		opacity: 0.5;
	}

	.frame__demos {
		margin: 0;
		grid-area: demos;
		justify-self: end;
	}

	.frame__links {
		grid-area: links;
		padding: 0;
		justify-self: end;
	}

	.frame a {
		pointer-events: auto;
	}

	.wel {
		height: 100vh;
		justify-content: center;
	}
}

.cursor {
	display: block;
}


@font-face {
	font-family: 'Heavitas';
	/* font-display: swap; */
	src: url('/public/Heavitas.ttf');
}

.chars {

	/* line-height: 0; */
	/* background-color: red; */
}

.char {
	font-family: 'Heavitas', -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
	/* font-family: highgate, sans-serif; */
}

video:hover {
	outline: none;
}

@media (any-pointer:fine) {
	.cursor {
		position: fixed;
		top: 0;
		left: 0;
		display: block;
		pointer-events: none;
		z-index: 1001;
	}

	.no-js .cursor {
		display: none;
	}

	.cursor__line {
		position: fixed;
		display: block;
		will-change: transform, opacity;
	}

	.cursor__line--horizontal {
		top: -10px;
		left: -10%;
		width: 120%;
		height: 20px;
	}

	.cursor__line--vertical {
		left: -10px;
		top: -10%;
		height: 120%;
		width: 20px;
	}

	.cursor__line-element {
		fill: none;
		stroke: var(--cursor-stroke);
		stroke-width: var(--cursor-stroke-width);
	}

}