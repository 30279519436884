/* 第五屏 */
.fifth_page {
  background-color: #fa4716;
  margin-left: 50%;
  height: 100vh;
  width: 120%;
}

.fifth_content {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  align-items: center;
  position: relative;
}

.fifth_flex {
  display: flex;
  width: 96vw;
  margin: auto;
}

.fifth_flex .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fifth_flex .item:nth-child(1) {
  padding-bottom: 6.6vw;
  flex: 45.8;
}

.fifth_flex .item:nth-child(2) {
  padding-top: 6.6vw;
  flex: 52.9;
}

.fifth_flex .item:nth-child(3) {
  flex: 56.1;
  padding-bottom: 4vw;
}

.fif_video_box {
  margin: 0;
  position: relative;
  width: 100%;
}

.fif_video_box .video_img {
  width: 90%;
  margin: auto;
}

.fif_img {
  width: 10vw;
  display: block;
  margin: 1vw 0;
}

.video_box {
  position: absolute;
  left: 0;
  top: 0;
  /* height: 10.9vw;
  width: 18vw; */
  width: 50%;
  overflow: hidden;
}

/*  */
.join {
  height: 8vw;
  width: 16vw;
  background-color: #fff;
  border-top-left-radius: 10vw;
  border-top-right-radius: 10vw;
  position: absolute;
  bottom: -3vw;
  left: 50%;
  transform: translateX(-50%);
  transition: .4s;
}

.join:hover {
  height: 10vw;
  width: 20vw;
  margin: auto;
}

.join:hover .join_arrow {
  display: block;
  opacity: 1;
}

.join_box {
  height: 8vw;
  width: 16vw;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.join_link {
  text-align: center;
  /* border-bottom: 1px solid black; */
  width: fit-content;
  margin: auto;
  margin-top: 2vw;
  font-weight: bold;
  font-size: .9vw;
  cursor: pointer;
  position: relative;
  padding-bottom: 5px;
  overflow: hidden;
}

.join_link::before {
  position: absolute;
  left: -130%;
  bottom: 0;
  content: '';
  width: 100%;
  height: 1px;
  transition: .4s;
  background-color: #333;
}

.join_link::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  width: 100%;
  height: 1px;
  background-color: #333;
  transition: .4s;
}

.join_box:hover .join_link::after {
  transform: translateX(130%);
}

.join_box:hover .join_link::before {
  transform: translateX(130%);
}

.join_arrow {
  opacity: 0;
  transition: .6s;
}

.join_arrow1 {
  position: absolute;
  left: 0;
  bottom: 72%;
  width: 16%;
  transform-origin: 70% 0%;
  animation: arrow1 1s ease-in-out 0s infinite alternate;
}

.join_arrow2 {
  position: absolute;
  right: 10%;
  bottom: 86%;
  width: 12%;
  transform-origin: 30% 0%;
  animation: arrow2 1s ease-in-out 0s infinite alternate;
}

.join_box__a {
  color: #121212;
  font-size: 0.875rem;
  text-align: center;
}

@keyframes arrow1 {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes arrow2 {
  0% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0);
  }
}


@media screen and (max-width:812px) {
  .fifth_page {
    margin-left: 0%;
    height: auto;
    width: 100%;
    overflow: hidden;
  }

  .fifth_content {
    height: auto;
    margin-top: 40px;
  }

  .fifth_flex {
    flex-direction: column;
    margin: auto;
    min-height: 120vh;
  }

  .fif_img {
    display: none !important;
  }

  .fifth_flex .item {
    flex: 1;
    margin: 0 auto;
    /* margin-bottom: 100px; */
  }

  body {
    overflow-x: hidden;
  }

  .fif_video_box {
    margin: 0;
  }

  .video_box {
    width: 50%;
    height: 44%;
  }

  .fifth_flex .item:nth-child(1) {
    flex: 1;
    width: 80%;
    padding-bottom: 0vh;
  }

  .fifth_flex .item:nth-child(2) {
    padding-bottom: 2vh;
    padding-left: 0;
    flex: 1;
    width: 90%;
  }

  .fifth_flex .item:nth-child(3) {
    flex: 1;
  }

  .fifth_flex .item:nth-child(1) .video_box {
    width: 57%;
  }

  .fifth_flex .item:nth-child(2) .video_box {
    height: 50%;
  }

  .fifth_flex .item:nth-child(3) .video_box {
    height: 46%;
    width: 49.2%;
  }

  .video_img {
    width: 100%;
  }


  .join {
    bottom: 0;
    margin-bottom: -5.3vw;
    height: 18vw;
    width: 30vw;
    border-top-left-radius: 15vw;
    border-top-right-radius: 15vw;
  }

  .join_link {
    margin-top: 5vw;
    font-size: 3vw ;
    font-weight: bold;
  }
  .join_box{
    height: 18vw;
    width: 30vw;
  }
  .join:hover{
    /* margin-top: 2vw; */
    height: 18vw;
    width: 30vw;
    margin-bottom: -5.3vw;
  }
}
