/* 第一屏 */
.first_page {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: #fb4716; */
}

.first_back_img {
  position: absolute;
  z-index: 0;
  width: 200%;
  height: 100%;
}

.first_back_img img {
  min-height: 100%;
  min-width: 100%;
}

/* ----------- */
.content_top {
  display: flex;
  flex-direction: row;
  flex: 1;
  z-index: 1;
  width: 100%;
  position: relative;
}

.content_top::after {
  content: ' ';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
  /* background-image: linear-gradient(to right, #fff 0%, #fff 70%, transparent 50%); */
  background-size: 20px 1px;
  background-repeat: repeat-x;
}

.content_top .item:nth-child(1) {
  flex: 1;
  position: relative;
  /* border-right: 1px dashed #fff; */
  /* background-image: linear-gradient(to right, #ccc 0%, #ccc 50%, transparent 50%); */
}

.content_top .item:nth-child(1)::after {
  content: ' ';
  position: absolute;
  right: 0;
  width: 2px;
  height: 100%;
  /* background-image: linear-gradient(to bottom, #fff 0%, #fff 70%, transparent 50%); */
  background-size: 1px 20px;
  background-repeat: repeat-y;
}

.content_top .item:nth-child(2) {
  width: 30vw;
}

.first_page .first_head {
  background-color: #000000;
  height: 9.7vh;
  z-index: 1;
}

.first_content {
  position: absolute;
  height: 100vh;
  width: 100vw;
}

.first_content .first_welcome {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 88%;
  z-index: 1;
}

.first_content .first_fly {
  position: absolute;
  left: 57.5%;
  top: 46.4%;
  width: 16.8%;
  z-index: 1;
}

.first_content .first_ring {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 12.8%;
  transform: translate(-80%, -156%);
}

.first_content img {
  width: 100%;
}

.content_bottom {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.content_bottom .item:nth-child(1) {
  flex: 1;
  display: flex;
  align-items: center;
  z-index: 1;
  padding: 2vw 0;
  position: relative;
}

.content_bottom .item:nth-child(1)::after {
  content: ' ';
  position: absolute;
  right: 0;
  width: 2px;
  height: 100%;
  /* background-image: linear-gradient(to bottom, #fff 0%, #fff 70%, transparent 50%); */
  background-size: 1px 20px;
  background-repeat: repeat-y;
}

.first_person_box {
  height: 10vw;
  width: 10vw;
  min-width: 168px;
  min-height: 168px;
  position: relative;
  left: 2vw;
  overflow: hidden;
}

.first_person_box::before {
  content: ' ';
  position: absolute;
  background-image: url('/public/images/first_text.png');
  background-size: auto 100%;
  background-repeat: no-repeat;
  height: 10vw;
  width: 10vw;
  min-width: 168px;
  min-height: 168px;
  animation: textRotate linear 8s infinite;
}

@keyframes textRotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.first_page .first_person {
  position: relative;
  height: 9.23vw;
  min-height: 155px;
  animation: person steps(19, end) 1.4s infinite;
}

@keyframes person {
  100% {
    background-position-x: 100%;
  }
}


.content_bottom .item:nth-child(2) {
  width: 24vw;
  display: flex;
  align-items: center;
}

.first_page .first_arrow {
  position: absolute;
  right: 3vw;
  width: 16vw;
  z-index: 1;
  animation: arrow 3.4s infinite;
}

@keyframes arrow {
  0% {
    margin-right: 0;
  }

  5% {
    margin-right: -1vw;
  }

  10% {
    margin-right: 0;
  }

  15% {
    margin-right: -1vw;
  }

  20% {
    margin-right: 0;
  }
}

.first_page .first_arrow img {
  width: 100%;
}

@media screen and (max-width:812px) {
  .first_content{
    height: 100vh;
  }
  .first_back_img img{
    height: 100vh;
  }
  .first_person_box {
    left: 0;
  }

  .first_page .first_head {
    height: 68px;
  }

  .first_back_img {
    height: 100vh;
  }

  .first_content .first_fly {
    width: 18.8%;
    transform: translate(0, 10%);
  }

  .content_bottom {
    margin-bottom: 5%;
  }

  .content_bottom .item:nth-child(1) {
    justify-content: center;
  }

  .first_content .first_welcome {
    top: 40%;
  }

  .first_content .first_ring {
    top: 40%;
    width: 21.8%;
    transform: translate(-20%, -151%);
  }
}